<template>
  <van-form @submit="onSubmit" label-width="7em">
    <van-field v-model="form.dealer_name" name="dealer_name" label="经销商名称" placeholder="经销商名称" :rules="config.dealer_name==1?[{ required: true, message: '请填写经销商名称' }]:[]" :required="config.dealer_name==1?true:false" />
    <van-field v-model="form.dealer_company" name="dealer_company" label="店铺名/公司名" placeholder="店铺名/公司名" :rules="config.dealer_company==1?[{ required: true, message: '请填写店铺名/公司名' }]:[]" :required="config.dealer_company==1?true:false" />
    <van-field v-model="form.mobile" name="mobile" label="手机号" type="tel" placeholder="手机号" :rules="config.dealer_mobile==1?[{ required: true, message: '请填写手机号' }]:[]" :required="config.dealer_phone==1?true:false" />
    <van-field v-model="form.dealer_card" name="dealer_card" label="身份证号码" placeholder="身份证号码" :rules="config.dealer_card==1?[{ required: true, message: '请填写身份证号码' }]:[]" :required="config.dealer_card==1?true:false" />
    <van-field v-model="fieldValue" label="住址/店铺住址" is-link readonly placeholder="请填写住址/店铺住址" @click="show = true" :rules="config.dealer_caddress==1?[{ required: true, message: '请填写住址/店铺住址' }]:[]" :required="config.dealer_caddress==1?true:false" />
    <van-field v-model="form.detail" name="detail" label="住址/店铺住址详情" placeholder="住址/店铺住址详情" :rules="config.dealer_caddress==1?[{ required: true, message: '请填写住址/店铺住址详情' }]:[]" :required="config.dealer_caddress==1?true:false" />
    <van-field v-model="form.weixin" name="weixin" label="微信号" placeholder="微信号" :rules="config.dealer_wechat==1?[{ required: true, message: '请填写微信号' }]:[]" :required="config.dealer_wechat==1?true:false" />
    <van-field v-model="form.phone" name="phone" label="其他联系方式" placeholder="其他联系方式" :rules="config.dealer_mobile==1?[{ required: true, message: '请填写其他联系方式' }]:[]" :required="config.dealer_mobile==1?true:false" />
    <van-field v-model="form.dealer_consignee" name="dealer_consignee" label="收货人姓名" placeholder="收货人姓名" :rules="config.dealer_consignee==1?[{ required: true, message: '请填写收货人姓名' }]:[]" :required="config.dealer_consignee==1?true:false" />
    <van-field v-model="form.dealer_consignee_phone" name="dealer_consignee_phone" label="收货电话" placeholder="收货电话" :rules="config.dealer_consignee_phone==1?[{ required: true, message: '请填写收货电话' }]:[]" :required="config.dealer_consignee_phone==1?true:false" />
    <van-field v-model="fieldValue1" label="收货地址" is-link readonly placeholder="收货地址" @click="show1 = true" :rules="[{ required: true, message: '请填写收货地址' }]" :required="config.dealer_consignee_address==1?true:false" />
    <van-field v-model="form.consignee_detail" name="detail" label="收货地址详情" placeholder="收货地址" :rules="[{ required: true, message: '请填写收货地址' }]" required></van-field>
    <van-field label="身份证" :required="config.dealer_card_pic==1?true:false">
      <template #input>
        <van-uploader :max-count="1" preview-image :after-read="afterRead1">
          <div class="uploader_content">
            <img :src="form.dealer_card_pic_z?form.dealer_card_pic_z:require('@/assets/images/shenfenzhengzhengmian.png')" alt="">
            <p>身份证正面</p>
          </div>
        </van-uploader>
        <van-uploader v-model="uploader" :max-count="1" preview-image :after-read="afterRead2">
          <div class="uploader_content">
            <img :src="form.dealer_card_pic_f?form.dealer_card_pic_f:require('@/assets/images/shenfenzhengfanmian.png')" alt="">
            <p>身份证反面</p>
          </div>
        </van-uploader>
      </template>
    </van-field>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">提交</van-button>
    </div>
  </van-form>
  <van-popup v-model:show="show" round position="bottom" key='1'>
    <van-cascader :field-names="fieldNames" v-model="cascaderValue" title="请选择所在地区" :options="options" @close="show = false" @change="onChange" @finish="onFinish" />
  </van-popup>
  <van-popup v-model:show="show1" round position="bottom" key='2'>
    <van-cascader :field-names="fieldNames" v-model="cascaderValue" title="请选择所在地区" :options="options1" @close="show1 = false" @change="onChange1" @finish="onFinish1" />
  </van-popup>
</template>
<script>
import {
  getDconfig,
  getProvince,
  getCity,
  getDistrict,
  doupload,
  dealerReg,
} from "@/api/index";
import { onMounted, reactive, toRefs } from "vue";
import { Toast } from "vant";
import { useRouter } from 'vue-router';
const fieldNames = {
  text: "text",
  value: "region_id",
};
export default {
  setup() {
    const router=useRouter()
    const state = reactive({
      config: {},
      cascaderValue: "",
      fieldValue1: "",
      show: false,
      show1: false,
      options: [
        {
          text: "浙江省",
          value: "330000",
          children: [],
        },
      ],
      options1: [
        {
          text: "浙江省",
          value: "330000",
          children: [],
        },
      ],
      fieldValue: "",
      form: {
        dealer_name: "",
        phone: "",
        weixin: "",
        mobile: "",
        dealer_caddress: "",
        dealer_card: "",
        dealer_card_pic: "",
        dealer_company: "",
        dealer_consignee: "",
        dealer_consignee_address: "",
        dealer_consignee_phone: "",
        dealer_card_pic_z: "",
        dealer_card_pic_f: "",
        consignee_country: "1",
        country: 1,
        province: "",
        city: "",
        detail: "",
      },
    });
    const initData = async () => {
      let result = await getDconfig()
        .then((res) => res.data)
        .catch((error) => error);
      state.config = result.dconfig;
      let options = await getProvince()
        .then((res) => res.data)
        .catch((error) => error);
      state.options = options.msg;
      state.options1 = options.msg;
    };
    onMounted(() => {
      initData();
    });
    const onSubmit = async (e) => {
      let result = await dealerReg(state.form)
        .then((res) => res.data)
        .catch((error) => error);
      console.log(result, e);
      if (result.code == 200) {
        Toast.success(result.msg);
        router.push({path:'/result'})
      } else {
        Toast.fail(result.msg);
      }
    };
    const afterRead1 = async (e) => {
      let result = await doupload({ image: e.content })
        .then((res) => res.data)
        .catch((error) => error);
      state.form.dealer_card_pic_z = result.url;
    };
    const afterRead2 = async (e) => {
      let result = await doupload({ image: e.content })
        .then((res) => res.data)
        .catch((error) => error);
      state.form.dealer_card_pic_f = result.url;
    };
    const onChange = async function ({ selectedOptions, value, tabIndex }) {
      state.form.province = "";
      state.form.city = "";
      state.form.area = "";
      if (tabIndex == 0) {
        var index = state.options.findIndex((v) => v.region_id == value);
        let result = await getCity({ parent_id: value })
          .then((res) => res.data)
          .catch((error) => error);
        state.options[index].children = result.msg;
      } else if (tabIndex == 1) {
        state.form.city = value;
        var pIndex = state.options.findIndex(
          (v) => v.region_id == selectedOptions[0].region_id
        );
        var p = state.options.find(
          (v) => v.region_id == selectedOptions[0].region_id
        );
        var cIndex = p.children.findIndex((v) => v.region_id == value);
        let result = await getDistrict({ parent_id: value })
          .then((res) => res.data)
          .catch((error) => error);
        state.options[pIndex].children[cIndex].children = result.msg;
      }
    };
    const onFinish = function ({ selectedOptions }) {
      if (selectedOptions.length == 3) {
        state.show = false;
        state.form.province = selectedOptions[0].region_id;
        state.form.city = selectedOptions[1].region_id;
        state.form.area = selectedOptions[2].region_id;
      }
      state.fieldValue = selectedOptions.map((option) => option.text).join("/");
    };
    const onChange1 = async function ({ selectedOptions, value, tabIndex }) {
      state.form.consignee_province = "";
      state.form.consignee_city = "";
      state.form.consignee_area = "";
      if (tabIndex == 0) {
        var index = state.options1.findIndex((v) => v.region_id == value);
        let result = await getCity({ parent_id: value })
          .then((res) => res.data)
          .catch((error) => error);
        state.options1[index].children = result.msg;
      } else if (tabIndex == 1) {
        state.form.city = value;
        var pIndex = state.options1.findIndex(
          (v) => v.region_id == selectedOptions[0].region_id
        );
        var p = state.options1.find(
          (v) => v.region_id == selectedOptions[0].region_id
        );
        var cIndex = p.children.findIndex((v) => v.region_id == value);
        let result = await getDistrict({ parent_id: value })
          .then((res) => res.data)
          .catch((error) => error);
        state.options1[pIndex].children[cIndex].children = result.msg;
      }
    };
    const onFinish1 = function ({ selectedOptions }) {
      if (selectedOptions.length == 3) {
        state.show1 = false;
        state.form.consignee_province = selectedOptions[0].region_id;
        state.form.consignee_city = selectedOptions[1].region_id;
        state.form.consignee_area = selectedOptions[2].region_id;
      }

      state.fieldValue1 = selectedOptions
        .map((option) => option.text)
        .join("/");
    };
    return {
      onSubmit,
      afterRead1,
      afterRead2,
      ...toRefs(state),
      onChange,
      onFinish,
      onChange1,
      onFinish1,
      fieldNames,
    };
  },
};
</script>
<style lang="less" scoped>
.uploader_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 14px;
  color: #666;
  img {
    width: 80px;
    height: 80px;
  }
}
</style>